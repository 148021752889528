import React, { useCallback, useEffect, useMemo, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dropdown, Layout, message } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../../assets/logo/Logo.png";
import LceedataButton from "../../ui/Button/Button";
import { getModalVisible } from "../../redux/selectors/modal";
import Dashboard from "../../pages/Dashboard/Dashboard";
import _upperFirst from "lodash/upperFirst";

import LceedataDrawer from "./Layout.Drawer";
import {
  NAV_LOGIN,
  NAV_SIGN_UP_BASE,
} from "../../routes/routes.constant";
import { getNavPath } from "../../routes/routes.helpers";

import { fetchUser, logout } from "../../redux/actions/user";
import LceedataFooter from "../Footer/Footer";
import Icon from "../../assets/Icon";
import withUserData from './../../HOC/withUserData';
import { redirectAfterDelay, sleep } from "../../utils/utils";
import useIdleTimer from "../../hooks/useIdleTimer";
import TransactionsIcon from "../../assets/svgs/menu-icons/transactions";
import AccountIcon from '../../assets/svgs/menu-icons/account';
import LogoutIcon from "../../assets/svgs/menu-icons/logout";
import { setAuthPin } from "../../redux/actions/auth";
import { checkPinAuthRequired } from "../../redux/selectors/auth";
import useSpModal from "../../sp-components/SpModal/SpModal";


const Item = ({ icon, label, color="#000" }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        justifyItems: 'center',
        minWidth: 180,
        columnGap: 8,
        padding: "0.2rem 0rem",
      }}
    >
      {icon}<div style={{ flex: 1, color }}>{label}</div>
    </div>
  );
}

const mainNavItems = [
  {
    label: <Item icon={<TransactionsIcon />} label="My Transactions" />,
    link: "/transactions",
    key: "transactions"
  },
  {
    label: <Item icon={<AccountIcon />} label="My Account" />,
    link: '/account',
    key: 'account'
  },
  {
    label: <Item icon={<LogoutIcon />} label="Logout" />,
    link: '/logout',
    key: 'logout'
  },
];


const AppLayout = ({ children, hasSideMenu, rootClass = '', userData, isLoggedIn, isProtected = false }) => {
  const dispatch = useDispatch();
  const isModalVisible = useSelector(getModalVisible, shallowEqual);
  const [logoutInitiated, setInitiateLogout] = useState(false);
  const isPinAuthRequired = useSelector(checkPinAuthRequired);
  const location = useLocation();
  const { showPinAuth } = useSpModal();

  useEffect(() => {
    if (isPinAuthRequired) {
      showPinAuth({showCloseBtn: false, isLoggedIn});
    }
  }, [isPinAuthRequired, location]);

  const {
    firstName = "",
    lastName = "",

  } = userData;

  const [visible, setVisible] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = useCallback(
    () => setOpenDrawer(!openDrawer),
    [openDrawer]
  );

  let navigate = useNavigate();

  const phoneScreenWidth = 600;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const handleLogout = useCallback(() => {
    setInitiateLogout(true);
    message.loading("Logging out...")

    sleep(1000).then(() => {
      dispatch(logout());
    })
  }, [dispatch]);

  const navigateToHome = useCallback(() => {
    redirectAfterDelay('/');
  }, []);

  useEffect(() => {
    if (isProtected && !isLoggedIn) {
      redirectAfterDelay('/login');
    }
  }, [isProtected, isLoggedIn]);

  const userInitials = useMemo(
    () =>
      `${_upperFirst(firstName?.substring(0, 1))}${_upperFirst(
        lastName?.substring(0, 1)
      )}`,
    [firstName, lastName]
  );

  const handleButtonClick = () => {
    setVisible(!visible);
  };

  const handleNav = useCallback((url) => {
    if (isLoggedIn) {
      navigate(url);
    }
    else {
      navigate('/login', {}, url);
    }
  }, [navigate, isLoggedIn]);

  const onIdle = useCallback(() => {
    if (isLoggedIn) {
      dispatch(fetchUser());
    }
  }, [isLoggedIn, dispatch]);

  useIdleTimer(onIdle, 30000);

  useIdleTimer(() => {
    if (isLoggedIn) {
      dispatch(setAuthPin(true));
    }
  }, 100000);

  const items = [
    {
      key: "1",
      icon: <Icon icon="calling" width={13} height={13} />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href={`tel:${process.env.REACT_APP_CONTACT_PHONE}`}>
          {!isPhoneScreen ? process.env.REACT_APP_CONTACT_PHONE : ""}
        </a>
      ),
    },
    {
      key: "2",
      icon: <Icon icon="email-open" width={16} height={16} />,
      label: (
        <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
          {!isPhoneScreen ? process.env.REACT_APP_CONTACT_EMAIL : ""}
        </a>
      ),
    },
    {
      key: "3",
      icon: <Icon icon="message-bubble" width={16} height={16} />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.REACT_APP_CONTACT_WHATSAPP}
        >
          {!isPhoneScreen ? "Whatsapp Support" : ""}
        </a>
      ),
    },
    /* {
      key: "4",
      icon: <Icon icon="message-question" width={16} height={16} />,
      label: (
        <Link onClick={handleButtonClick} to="/about-us">
          {!isPhoneScreen ? "FAQS" : ""}
        </Link>
      ),
    }, */
  ];

  const handleAvatarMenuClicked = (e) => {
    const key = e.key;

    if (['transactions', 'account'].includes(key)) {
      redirectAfterDelay('/' + key);
    }
    else if (['logout'].includes(key)) {
      handleLogout();
    }
  }

  useEffect(() => {
    if (!isLoggedIn && logoutInitiated) {
      message.success("Logged out successfully!");
      setInitiateLogout(false);
    }
  }, [isLoggedIn, logoutInitiated])



  const RightNavSection = () => {
    return (
      <div className="layout__flex_end">
        <div className="layout__need_help">
          <Dropdown
            placement={"bottomCenter"}
            menu={{
              items,
              onClick: handleButtonClick
            }}
            trigger={["click"]}
            overlayClassName="layout__need-help_menu"
          >
            <div style={{
              display: "flex",
              alignItems: 'center',
              columnGap: 4
            }}>
              {isPhoneScreen? "" : "Support"}
              <Icon
                icon="question-mark-circle"
                width={16}
                height={16}
              />
            </div>
          </Dropdown>
        </div>

        {
          !isLoggedIn && <div style={{ display: 'flex', columnGap: 8 }}>
          <LceedataButton
            onClick={() => redirectAfterDelay(getNavPath(NAV_LOGIN))}
            label="Login"
            customClass="layout__button"
            shape="round"
            size="sm"
            buttonType="bordered"
          />
          <LceedataButton
            onClick={() => redirectAfterDelay(getNavPath(NAV_SIGN_UP_BASE))}
            label={isPhoneScreen? 'Sign Up' : "Create an Account"}
            customClass="layout__button"
            shape="round"
            size="sm"
            buttonType="primary"
          />
        </div>
        }

        {isLoggedIn && <div className="avatar_text">

          <Dropdown
            menu={{
              items: mainNavItems,
              onClick: handleAvatarMenuClicked
            }}
            trigger={['click']}
          >
            <span>{userInitials}</span>

          </Dropdown>
          </div>}
      </div>
    );
  }

  return (
    <Layout className={`container ${rootClass}`}>
      <LceedataDrawer showDrawer={openDrawer} onClose={toggleDrawer} />
      <Layout className={`layout ${isModalVisible ? "blur" : ""}`}>
        <Header className="layout__header">
          <div className="layout__nav">
            <img
              className="layout__logo"
              src={logo}
              alt=""
              onClick={navigateToHome}
            />
            <RightNavSection />
          </div>

          <div className="layout__nav_mobile">
            {isLoggedIn && <MenuOutlined onClick={toggleDrawer} style={{ fontSize: 20 }} />}
            {
              !isLoggedIn && <img
                className="layout__logo"
                src={logo}
                alt=""
                onClick={navigateToHome}
              />
            }
            <RightNavSection />
          </div>
        </Header>
        <main>
          {hasSideMenu ? (
            <div className="app-layout">
              <Dashboard>{children}</Dashboard>
            </div>
          ) : (
            <Content className="dashboard">
              <Layout className="dashboard__layout main__layout">
                <Content className="dashboard__content layout__child">
                  {children}
                </Content>
              </Layout>
            </Content>
          )}
        </main>
        <LceedataFooter />
      </Layout>
    </Layout>
  );
};

export default withUserData(AppLayout);
